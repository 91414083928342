/** @jsx jsx */
import React from "react"
import { Styled, Box, jsx } from "theme-ui"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductList from "../components/productList"
import ProductCard from "../components/productCard"
import { Gradient } from "phosphor-react"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Etusivu" />
    <Box
      sx={{
        fontFamily: "display",
        fontSize: [3, 4, 5],
        textAlign: "center",
        my: [4, 5],
        maxWidth: 860,
        mx: "auto",
      }}
    >
      Santevin on samppanjoihin erikoistunut maahantuonnin tukkuliike. Liike
      sijaitsee Tampereen ydinkeskustassa{" "}
      <Styled.a href="http://www.champagnebarsante.fi">
        Champagne bar Santén
      </Styled.a>{" "}
      yhteydessä.
    </Box>

    <ProductList>
      {data.allContentfulProduct.edges.map(({ node }, i) => (
        <ProductCard product={node} key={i} />
      ))}
    </ProductList>

    <Box sx={{ textAlign: "center" }}>
      <Styled.a
        as={Link}
        to="/tuotteet"
        sx={{
          display: "inline-block",
          textTransform: "uppercase",
          fontSize: 1,
          letterSpacing: ".2em",
          fontWeight: "bold",
        }}
      >
        Näytä kaikki tuotteet &rarr;
      </Styled.a>
    </Box>

    <Box
      sx={{
        maxWidth: "60ch",
        mx: "auto",
        mt: 6,
        mb: 2,
      }}
    >
      <Styled.h2 sx={{ textAlign: "center", mb: 0 }}>Tapahtumat</Styled.h2>
      <Box
        sx={{
          position: "relative",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: 0,
          rowGap: 4,
          py: 4,

          ":after": {
            position: "absolute",
            right: "calc(50% - 1px)",
            display: "block",
            content: '""',
            width: 1,
            height: "100%",
            background:
              "linear-gradient(to bottom, white, #BD8756 25%, #BD8756 75%, white)",
            opacity: 0.25,
          },

          dt: {
            position: "relative",
            textAlign: "right",
            pr: 3,
            ":after": {
              position: "absolute",
              right: "-3px",
              top: ".5em",
              display: "block",
              content: '""',
              width: 5,
              height: 5,
              borderRadius: 5,
              backgroundColor: "champagne",
            },
          },
          dd: {
            m: 0,
            pl: 3,
          },
        }}
      >
        {data.allContentfulEvent.edges.map(({ node }, i) => (
          <>
            <dt>{node.startDate}</dt>
            <dd>{node.description.description}</dd>
          </>
        ))}
      </Box>
    </Box>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    allContentfulProduct(
      filter: { featuredProduct: { eq: true } }
      sort: { fields: [producer___name, orderNumber] }
    ) {
      edges {
        node {
          name
          slug
          externalLink
          producer {
            name
          }
          image {
            fluid(maxHeight: 512, maxWidth: 256) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
    allContentfulEvent(sort: { fields: startDate }) {
      edges {
        node {
          startDate(formatString: "D.M.YYYY", locale: "fi-FI")
          endDate(formatString: "D.M.YYYY", locale: "fi-FI")
          description {
            description
          }
        }
      }
    }
  }
`
